import { useEffect, useRef, useState } from "react";
import { UserIdleConfig } from "models/union-config";
import { useAppSelector } from "redux/hooks";
import { loadMenu } from "services/menu-builder/menu-builder.service";
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import { Timeout } from "components/timeout";
import { getVersions } from "services/version-helper/version-helper.service";
import { SessionStorageService } from "services/storage-service";
import { AppRoutes } from "router/routes";
import { loadUser } from "services/user/user.service";
import { loadPermission } from "services/permission/permission.service";
import { loadProductRoutes } from "router/routes/router.services";
import { interceptor } from "services/interceptor";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { UnionLoader } from "components/shared/loader";
import "./app.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import { UnionConfigLoader } from "services/union-config/union-config.service";

library.add(fas);

export function App() {
  let idleTimer = null as IIdleTimer;
  const userTimeoutConfig = useRef({} as UserIdleConfig);
  const [isIdle, setIsIdle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayTimeoutModal, setDisplayTimeoutModal] = useState(false);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  // Need to fix in future
  const timer = useRef(1200000);

  useEffect(() => {
    if (location.host.includes("reactui.dev")) {
      location.replace("https://dev.finapps.ihsmarkit.com/");
    } else if (location.host.includes("reactui.qa")) {
      location.replace("https://qa.finapps.ihsmarkit.com/");
    } else if (location.host.includes("reactui.uat")) {
      location.replace("https://uat.finapps.ihsmarkit.com/");
    }
  });

  useEffect(() => {
    UnionConfigLoader();
    loadUser();
  }, []);

  useEffect(() => {
    if (unionConfig.manifest) {
      interceptor(unionConfig);
      const loadData = async () => {
        await Promise.allSettled([
          loadPermission(unionConfig),
          loadProductRoutes(unionConfig),
        ]);
        loadMenu(unionConfig);
        setLoading(false);
      };
      loadData();
      getVersions(unionConfig);
    }
  }, [unionConfig, unionConfig.manifest]);

  useEffect(() => {
    if (unionConfig.userIdleTimeoutInSeconds) {
      //Fetching config from sessionStorage for Automation Testing
      const configValues: UserIdleConfig = SessionStorageService.getItem(
        "sessionTimeoutConfig",
        "sessionTimeoutConfig"
      );
      if (configValues.timeout && configValues.idle) {
        userTimeoutConfig.current.idle = configValues.idle;
        userTimeoutConfig.current.timeout = configValues.timeout;
      } else {
        userTimeoutConfig.current.idle =
          unionConfig.userIdleTimeoutInSeconds.idle;
        userTimeoutConfig.current.timeout =
          unionConfig.userIdleTimeoutInSeconds.timeout;
      }

      //setting autoLogoutTime values
      const timeoutMinutes =
        userTimeoutConfig.current.idle + userTimeoutConfig.current.timeout;
      SessionStorageService.setItem(
        "autoLogoutTime",
        JSON.stringify(new Date(new Date().valueOf() + timeoutMinutes * 1000)),
        "autoLogoutTime"
      );

      timer.current = userTimeoutConfig.current.idle * 1000;
      setTimeout(() => {
        idleTimer.start();
      });
    }
  }, [idleTimer, unionConfig.userIdleTimeoutInSeconds]);

  window.addEventListener("isUserActive", () => {
    onAction();
  });

  const onIdle = () => {
    if (!isIdle) {
      setIsIdle(true);
      setDisplayTimeoutModal(true);
    }
  };

  const onAction = () => {
    if (!displayTimeoutModal) {
      //setting autoLogoutTime values
      const timeoutMinutes =
        userTimeoutConfig.current.idle + userTimeoutConfig.current.timeout;
      SessionStorageService.setItem(
        "autoLogoutTime",
        JSON.stringify(new Date(new Date().valueOf() + timeoutMinutes * 1000)),
        "autoLogoutTime"
      );
      setIsIdle(false);
      idleTimer.reset();
      idleTimer.start();
    }
  };

  idleTimer = useIdleTimer({
    timeout: timer.current,
    onIdle,
    onAction,
    startManually: true,
  });

  const handleContinueSession = () => {
    idleTimer.reset();
    setDisplayTimeoutModal(false);
    setIsIdle(false);
    idleTimer.start();
  };

  return (
    <>
      {loading ? <UnionLoader /> : <AppRoutes />}
      {displayTimeoutModal && (
        <Timeout
          showModalHandler={handleContinueSession}
          id="user-idle-modal"
        />
      )}
    </>
  );
}
