import { Modal, ModalProps } from "@medm-ui/modal";
import "./modal.css";

export function UnionModal(props: ModalProps) {
  return (
    <Modal
      children={props.children}
      size={props.size}
      title={props.title}
      actions={props.actions}
      onClose={props.onClose}
      links={props.links}
      id={props.id}
      displayCloseIcon={props.displayCloseIcon}
      actionsLayout={"end"}
      actionBarStyles={props.actionBarStyles}
    ></Modal>
  );
}
