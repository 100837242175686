import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menu: null,
  },
  reducers: {
    setMenu: (state, action) => {
      return {
        ...state,
        menu: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setMenu } = menuSlice.actions;

export default menuSlice.reducer;
