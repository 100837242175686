import { Button } from "@medm-ui/button";
import { Manifest } from "models";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import "./sandbox.css";

export function Sandbox(props: any) {
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const [sandboxState, setSandboxState] = useState({
    manifestData: {} as Manifest,
    toggle: false
  })

  useEffect(() => {
    const rawManifest = localStorage.getItem("manifest");
    if (rawManifest) {
      setSandboxState({
        manifestData: JSON.parse(rawManifest),
        toggle: true
      })
    } else {
      setSandboxState((prev) => ({
        ...prev,
        manifestData: unionConfig.manifest
      }))
    }
  }, []);

  function validateManifest(manifest: Manifest) {
    const errors = Array<string>();
    if (typeof manifest === "string") {
      manifest = JSON.parse(manifest);
    }

    if (!manifest || !manifest.length) {
      errors.push("Invalid Manifest");
    } else {
      manifest.forEach((product: any) => {
        {
          if (!product.key) {
            errors.push("Product key required: " + product.name);
          }
          if (!product.name) {
            errors.push("Product name required: " + product.name);
          }
          if (!product.environment.appRootUrl) {
            errors.push("Product appRootUrl required: " + product.name);
          }
          if (!product.color) {
            errors.push("Product color required: " + product.name);
          }
          if (!product.environment.apiEndpoints) {
            errors.push(
              "Product environment.apiEndpoints required: " + product.name
            );
          } else {
            if (!product.environment.apiEndpoints.routes) {
              errors.push(
                "Product environment.apiEndpoints.routes required: " +
                product.name
              );
            }
            if (!product.environment.apiEndpoints.menu) {
              errors.push(
                "Product environment.apiEndpoints.menu required: " +
                product.name
              );
            }
          }
        }
      });
    }
    return errors;
  }

  function handleChange(event: any) {
    setSandboxState((prev) => ({ ...prev, manifestData: event.target.value }))
  }

  function handleSave(event: any) {
    try {
      if (sandboxState.toggle) {
        const validationErrors = validateManifest(JSON.parse(JSON.stringify(sandboxState.manifestData)));
        if (validationErrors.length) {
          validationErrors.forEach((error) => {
            alert(error);
          });
          return;
        }
        if (typeof sandboxState.manifestData === 'string') {
          localStorage.setItem("manifest", sandboxState.manifestData);
        } else {
          localStorage.setItem("manifest", JSON.stringify(sandboxState.manifestData));
        }
        window.location.href = window.location.origin;
      } else {
        localStorage.removeItem("manifest");
        window.location.href = window.location.origin;
      }
    } catch (error) {
      alert(error);
    }
  }

  function handleCancel(event: any) {
    try {
      localStorage.removeItem("manifest");
      window.location.href = window.location.origin;
    } catch (error) {
      alert(error);
    }
  }

  return (
    <div className="sandbox-section">
      <p className="box notice">Developer Use Only</p>
      <h2 className="sandbox-mode-text">Sandbox Mode</h2>
      <p className="sandboxing-paragraph">
        Sandboxing allows you to locally override the Union Manifest.
      </p>
      <div className="switch">
        <div className="sandboxContainer">
          <label className="sandbox-switch">
            <input type="checkbox" onChange={() => setSandboxState((prev) => ({ ...prev, toggle: !sandboxState.toggle }))} checked={sandboxState.toggle} />
            <span className="slider round"></span>
          </label>
          <span className="sandbox-text">Activate Sandboxing</span>
        </div>
      </div>

      {sandboxState.toggle ? (
        <div>
          <h3 className="product-manifest">Product Manifest</h3>
          <p>
            Configure the Union Product Manifest for your local environment
            below. This configuration will be saved to your browser's local
            storage.
          </p>
          <p>
            <b className="warning">Warning</b>: There is currently no
            validation. Modify at your own risk! If you make a change that
            breaks the application, remove the "manifest" entry from your
            localStorage.
          </p>
          <textarea
            id="JSONdata"
            defaultValue={JSON.stringify(sandboxState.manifestData, undefined, 4)}
            cols={100}
            rows={20}
            onChange={handleChange}
            autoFocus
          ></textarea>
        </div>
      ) : null}

      <div className="float-right button button-primary">
        <Button
          buttonId={"saveBtn"}
          buttonLabel={"Save"}
          buttonType={"Primary"}
          buttonSize={"large"}
          disabled={false}
          onButtonClick={handleSave}
          iconProps={{ icon: "fa-floppy-disk", size: "lg", color: "white" }}
          hasFontAwesomeIcon={true}
          showIcon={true}
          className="first-button"
        />
        <Button
          buttonId={"cancelBtn"}
          buttonLabel={"Cancel"}
          buttonType={"Subtle"}
          buttonSize={"large"}
          disabled={false}
          onButtonClick={handleCancel}
        />
      </div>
    </div>
  );
}
