import React from "react";
import ReactDOM from "react-dom/client";
import { PreloadAuthenticator } from "./preload-authenticator";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { App } from "app";

const authenticator = new PreloadAuthenticator();
if (authenticator.callbackPending) {
  authenticator.handleCallback();
} else if (authenticator.callbackError) {
  authenticator.handleError();
} else if (authenticator.authenticationRequired) {
  authenticator.authenticateUser();
} else {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
