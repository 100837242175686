import { Sidebar } from "@medm-ui/sidebar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { lastVisitedPage } from "services/last-visited-page/last-visited-page-helper";
import { getPermission } from "services/permission/permission-helper";
import "./sideNav.css";

export function SideNav() {
  const menu = useAppSelector((state) => state.menu.menu);
  const permission = useAppSelector((state) => state.permission.permission);
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);
  const routeMap = useAppSelector((state) => state.route.routeMap);
  const [sideNav, setSideNav] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [favourites, SetFavourites] = useState([]);
  let favCopy = [...favourites];

  const saveFavourites = (data: any) => {
    SetFavourites(data);
    favCopy = [...data];
  };
  useEffect(() => {
    localStorage.removeItem("breadcrumb");
    const breadcrumb = [] as any;
    const setMenu = () => {
      const data = new Promise((resolve) => {
        localStorage.removeItem("breadcrumb");
        let sideData: any[] = [
          {
            icon: "home-lg",
            label: "Home",
            path: "/",
            current: pathname === "/",
          },
        ];
        if (menu) {
          menu.forEach((x: any) => {
            let data = null;
            //If product is having only one menu icon to display, then only the product name must be displayed.
            const productSubMenu = fetchChildItems(x.items);
            if (productSubMenu.childItem.length === 1) {
              data = {
                icon: x.iconClass,
                label: x.label,
                path: productSubMenu.childItem[0].path,
                current:
                  pathname === x.items[0].path ||
                  (pathname.includes("unionadmin/update") &&
                    x.items[0].productKey === "unionadmin"),
              };
              if (data.current) {
                breadcrumb.push(x.label)
              }
            } else {
              if (pathname.includes(x.productKey)) breadcrumb.push(x.label);
              data = {
                icon: x.iconClass,
                label: x.label,
                current: pathname.includes(x.productKey),
                childNavItems: productSubMenu.childItem,
              };
            }
            if (data && data.childNavItems?.length !== 0)
              sideData = [...sideData, data];
          });
          resolve(sideData);
        }
      });
      data.then((res: any) => {
        setSideNav(res);
      });
    };

    let favItems: any = JSON.parse(localStorage.getItem("favourites"));
    function fetchChildItems(item: any) {
      const childData: any = [];
      let isActive = false;
      item.forEach((x: any) => {
        if (x.path === pathname) {
          isActive = true;
          breadcrumb.push(x.label);
        }
        const d: any = {
          icon: x.iconClass,
          label: x.label,
          path: x.path,
          current: x.path === pathname,
        };
        if (x.items) {
          const { childItem, current } = fetchChildItems(x.items);
          if (childItem.length) {
            d.childNavItems = childItem;
            d.current = current;
            if (current) {
              breadcrumb.push(x.label);
            }
          }
        }
        let hasPermssion = false;
        if (x.permissions) {
          hasPermssion = getPermission(x, permission);
        }
        if (!x.permissions || hasPermssion) {
          if (
            d.path !== undefined ||
            (d.path === undefined && d.childNavItems?.length)
          ) {
            childData.push(d);
          }
        } else {
          if (favItems) {
            const index = favItems.findIndex(
              (element: any) => element.path === x.path
            );
            if (index !== -1) {
              favItems.splice(index, 1);
            }
          }
        }
      });
      return { childItem: childData, current: isActive };
    }
    setMenu();
    if (breadcrumb.length > 1) localStorage.setItem('breadcrumb', breadcrumb);

    if (favItems) {
      if (menu) {
        favItems = favItems.filter(
          (item: any) =>
            menu.findIndex(
              (x: any) => x.productKey === item.path.split("/")[1]
            ) !== -1
        );
      }
      saveFavourites(favItems);
    }
  }, [menu, permission, pathname]);

  const toggleStyle = {
    height: "50px",
  };

  return (
    <>
      <div className="side-nav">
        <Sidebar
          id="side-nav"
          navItems={sideNav}
          favouritesNavItems={favourites}
          toggleStyle={toggleStyle}
          onNavigate={(data: any) => {
            if (data.path && data.path === "/") {
              navigate("/");
            } else {
              const route = routeMap.find((x: any) => x.path.match(data.path));

              if (route.popout) {
                const prod = unionConfig.manifest.find(
                  (x: { key: any }) => x.key === route.productKey
                );
                lastVisitedPage(prod, unionConfig, route.path);
                if (route.popout === true) {
                  window.open(route.path, "_blank");
                } else {
                  window.open(
                    route.path,
                    route.popout.target,
                    route.popout.features
                  );
                }
              } else {
                navigate(route.path);
              }
            }
          }}
          onFavouritesNavItems={(data: any, isARemoval: boolean) => {
            if (data) {
              let savedFavourites = JSON.parse(
                localStorage.getItem("favourites")
                  ? localStorage.getItem("favourites")
                  : "[]"
              );
              let removedItem: any[];
              if (isARemoval) {
                removedItem = favCopy.filter(
                  (item: any) =>
                    data.findIndex((x: any) => item.path === x.path) === -1
                );
              }
              if (savedFavourites.length > 0) {
                savedFavourites = savedFavourites.filter(
                  (item: any) =>
                    data.findIndex((x: any) => item.path === x.path) === -1
                );
                if (removedItem) {
                  savedFavourites = savedFavourites.filter(
                    (item: any) =>
                      removedItem.findIndex(
                        (x: any) => item.path === x.path
                      ) === -1
                  );
                }
              }
              localStorage.setItem(
                "favourites",
                JSON.stringify(data.concat(savedFavourites))
              );
              saveFavourites(data);
            }
          }}
          sidebarOpenWidth={273}
        ></Sidebar>
      </div>
    </>
  );
}
