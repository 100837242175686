import { useEffect } from "react";
import { useAppSelector } from "../../redux/hooks";

export function Logout(props: any) {
  const unionConfig = useAppSelector((state) => state.unionConfig.unionConfig);

  useEffect(() => {
    sessionStorage.clear();
    window.location.href = unionConfig.auth.logout;
  }, []);

  return (<></>);
}
