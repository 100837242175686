import { UserManagerSettings } from 'oidc-client';
import { LocalStorageService } from './services/storage-service';

export const getAuthSettings = (): Promise<UserManagerSettings> => {
    return new Promise((resolve, reject) => {
        fetch(window.location.origin + '/assets/union-config/' + window.location.hostname + '.json')
            .then(response => {
                response.json()
                    .then(value => {
                        const settings = {
                            authority: value.auth.authority,
                            client_id: value.auth.client_id,
                            redirect_uri: window.location.origin,
                            response_type: 'code',
                            scope: 'openid profile email',
                            silent_redirect_uri: window.location.origin + '/silent-refresh.html',
                            automaticSilentRenew: true,
                            validateSubOnSilentRenew: true,
                            monitorAnonymousSession: true,
                            filterProtocolClaims: true,
                            loadUserInfo: true,
                            revokeAccessTokenOnSignout: true
                        };
                        LocalStorageService.setItem('auth-settings', JSON.stringify(settings), 'auth-settings');
                        resolve(settings);
                    })
                    .catch(error => reject(error));
            })
            .catch(error => reject(error));
    });
};
