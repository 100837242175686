import { Loader } from "@medm-ui/loader";
import "./loader.css";

export function UnionLoader(props: any) {
  if (props.subtle == true) {
    return (
      <div>
        <div className="spinner">
          <Loader id={props.id} size={"small"} shade={"light"}></Loader>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className="backdrop"></div>
        <div className="loader">
          <Loader id={props.id} size={"large"} shade={"light"}></Loader>
        </div>
      </div>
    );
  }
}
