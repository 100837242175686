import { createSlice } from "@reduxjs/toolkit";
import { AuthToken } from "models";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authToken: {} as AuthToken,
    "user-info": sessionStorage["user-info"],
    accessToken: sessionStorage["accessToken"],
    path: "",
  },
  reducers: {
    setUserAuth: (state, action) => { 
      state["user-info"] = action.payload["user-info"];
      state.accessToken = action.payload.accessToken;
      state.authToken = action.payload.authToken;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserAuth } = authSlice.actions;

export default authSlice.reducer;
