import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./redux/features/auth";
import unionConfigReducer from "./redux/features/unionconfig";
import menuReducer from "redux/features/menu";
import versionsReducer from "redux/features/versions";
import routeReducer from "redux/features/routes";
import permissionReducer from "redux/features/permission";

const store = configureStore({
  reducer: {
    auth: authReducer,
    unionConfig: unionConfigReducer,
    menu: menuReducer,
    permission: permissionReducer,
    route: routeReducer,
    versions: versionsReducer
  },
});

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch