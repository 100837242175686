import { AllLastVisitedPages } from "components/allLastVisitedPages";
import { Home } from "components/home";
import { Layout } from "components/layout";
import { Logout } from "components/logout/logout";
import { PageNotFound } from "components/pageNotFound";
import { Sandbox } from "components/sandbox";
import { UnauthorizedScreen } from "components/unauthorized";
import { useEffect, useState } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

export const AppRoutes = () => {
  const routes = useAppSelector((state) => state.route.routes);
  const [productRoutes, setProductRoutes] = useState([]);

  let route: RouteObject[] = [
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "/sandbox",
          element: <Sandbox />,
        },
        {
          path: "/logout",
          element: <Logout />,
        },
        {
          path: "/lastVisited/:productkey",
          element: <AllLastVisitedPages />,
        },
        {
          path: "/unauthorise",
          element: <UnauthorizedScreen />
        },
        {
          path: "*",
          element: <PageNotFound />,
        }
      ],
    },
  ];

  useEffect(() => {
    route = [...route, ...routes];
    setProductRoutes(route);
  }, []);

  const element = useRoutes(productRoutes);
  return element;
};
