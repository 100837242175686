export class EventHubEvent {
  name: string;
  payload: any;
}

export class EventHub {
  private messageChannel: MessageChannel;
  private events: any;
  constructor(
    private iframe: any,
    private targetOrigin: any,
    private debugMode: boolean,
    private props: any = {}
  ) {
    this.events = {};
    this.messageChannel = new MessageChannel();
    console.log(`from event hub ${iframe}`);
    console.log(this.iframe);
    this.messageChannel.port1.onmessage = (e) => {
      //if (this.debugMode) {
      console.log("Event:", e.data);
      //}

      this.dispatch(e.data.name, e.data);
    };

    this.iframe.contentWindow.postMessage(
      { name: "EventHub", props },
      targetOrigin,
      [this.messageChannel.port2]
    );
  }

  subscribe(eventName: string, callback: (e: EventHubEvent) => void) {
    if (!this.events[eventName]) this.events[eventName] = [];
    this.events[eventName].push(callback);
  }
  emit(name: string, payload: any) {
    const event: EventHubEvent = { name, payload };
    //if (this.debugMode) {
    console.log("Union Event:", event);
    //}

    this.messageChannel.port1.postMessage(event);
  }
  dispatch(event: string, data: any) {
    if (!this.events[event]) return;
    this.events[event].forEach((callback: (arg0: any) => any) =>
      callback(data)
    );
  }
}

