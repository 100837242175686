import { useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItemData } from "@medm-ui/breadcrumb";
import "./breadcrumb.css";

export function UnionBreadcrumb(props: any) {
  const multiTenantCompanyName = JSON.parse(
    window.sessionStorage.getItem("multi-tenant-selection")
  );
  const navigate = useNavigate();
  const shouldDisplayCompanyName = multiTenantCompanyName ? Object.keys(multiTenantCompanyName).includes(
    props.breadcrumb[0]?.key
  ) : false;
  function onClick(index: number, item: BreadcrumbItemData) {
    if (item.key === "home" && item.key === item.label.toLowerCase()) {
      navigate("/");
    }
  }
  return (
    <>
      <Breadcrumb
        breadCrumbId={"BreadCrumb"}
        breadCrumbData={props.breadcrumb}
        disableLink={false}
        separatorSymbol="/"
        onClick={onClick}
      />
      {shouldDisplayCompanyName &&
        multiTenantCompanyName &&
        props.breadcrumb && (
          <span className="breadcrumb-compliance-company">
            {multiTenantCompanyName[props.breadcrumb[0].key].companyName}
          </span>
        )}
    </>
  );
}
