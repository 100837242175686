import { FeedbackType, UnionConfig } from "models";
import axios from "axios";

export async function sendFeedback(unionConfig: UnionConfig, data: FeedbackType) {
  const feedbackUrl = unionConfig.apiUrl + "/feedback";

  try {
    const response = await axios.post(feedbackUrl, data);
    return response.data;
  } catch (error: any) {
    console.error(error.response);
    throw error.response;
  }
}
