import { DataGrid, DataGridProps } from "@medm-ui/grid";
import "./grid.css";

export function UnionGrid(props: DataGridProps) {
  return (
    <div
      className="lastVisitedGrid"
      id="lastVisitedGrid"
      data-test-id="lastVisitedGrid"
    >
      <DataGrid {...props} id={props.id} />
    </div>
  );
}
