import { Input } from "@medm-ui/input";
import { Label } from "@medm-ui/label";
import { UnionGrid } from "../../components/shared/grid";
import { Page } from "../../models/last-visited-page/last-visited-page";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./allLastVisitedPages.css";
import iconSet from "./iconSet.json";

export function AllLastVisitedPages() {
  const location = useLocation();
  const navigate = useNavigate();
  const initialData = location.state.pages.map((p: Page) => {
    return {
      Name: (p?.url?.includes("@") && p?.url?.includes("unionadmin") && p?.url?.includes("rfr-calculator")) ? (`${p.pageName} : ${p.url.split("/")[3]}`) : p.pageName,
      LastOpened: p.lastOpened,
      Application: location.state.key,
    };
  });
  let lastVisitedData = initialData;
  const [takeCount, settakeCount] = useState(10);
  const [skipCount, setskipCount] = useState(0);
  const [pageSizeData, setpageSizeData] = useState([10, 20, 30]);
  const [gridData, setgridData] = useState(lastVisitedData.slice(0, takeCount));

  function UpdateGridBinding(inputvalue: string): void {
    if (inputvalue == "") {
      lastVisitedData = initialData.slice(skipCount, takeCount);
    } else {
      lastVisitedData = initialData
        .filter((p: { Name: string }) =>
          p.Name.toLowerCase().includes(inputvalue)
        )
        .slice(skipCount, takeCount);
    }
    setgridData(lastVisitedData);
  }

  function handlePageChange(skip: number, take: number): void {
    lastVisitedData = initialData.slice(skip, skip + take);
    setskipCount(skip);
    settakeCount(take);
    setgridData(lastVisitedData);
  }

  return (
    <>
      <Label
        label={
          location.state.key.charAt(0).toUpperCase() +
          location.state.key.substring(1)
        }
        size="Large"
        type="Header"
        weight="Bold"
        color="#222222"
        className="homePageTitleLabel"
      />
      <Label
        label={`Access all last visited pages in the ${location.state.key.charAt(0).toUpperCase() +
          location.state.key.substring(1)
          } app`}
        size="Small"
        type="Body"
        weight="Regular"
        color="#666666"
        className="homePageSubtitleLabel"
      />
      <div className="selectSection">
        <Label
          label="Your last visited"
          size="Medium"
          type="Body"
          weight="Bold"
          color="#222222"
          className="lastVisitedLabel"
        />
        <Input
          id={"search"}
          data-test-id={"search"}
          inputType="search"
          placeholder="Search for page name..."
          suffixIcon="search"
          widthSize="380px"
          disabled={false}
          required={false}
          readonly={false}
          inputCallbackFn={UpdateGridBinding}
          iconSet={iconSet}
        />
      </div>
      <div
        className="selectSection"
        id={`${location.state.key.charAt(0).toUpperCase() +
          location.state.key.substring(1)
          }`}
        data-test-id={`${location.state.key.charAt(0).toUpperCase() +
          location.state.key.substring(1)
          }`}
      >
        <UnionGrid
          id={`${location.state.key.charAt(0).toUpperCase() +
            location.state.key.substring(1)}`}
          data={gridData}
          pageSizes={pageSizeData}
          pageable={true}
          skip={skipCount}
          sortable={false}
          filterable={false}
          take={takeCount}
          total={initialData.length}
          reorderable={false}
          resizable={false}
          showloader={false}
          allowUnsort={false}
          allowEditing={false}
          filter={null}
          filterChangeHandler={null}
          gridHeight={"460px"}
          pageChangeHandler={handlePageChange}
          onRowClickHandler={(event: any) => {
            const rowdata = location.state.pages.find(
              (p: Page) => p.pageName == event.dataItem.Name || p.url?.includes(event.dataItem.Name.replace(/ /g, '').split(":")[1])
            );
            navigate(rowdata.url, { replace: false });
          }}
          headers={[
            {
              headerName: "Name",
              name: "Name",
              order: 0,
              type: "text",
              editable: false,
              format: null,
            },
            {
              headerName: "Last Opened",
              name: "LastOpened",
              order: 1,
              editable: false,
              type: "text",
            },
            {
              headerName: "Application",
              name: "Application",
              order: 2,
              type: "text",
            },
          ]} checkboxTypeFilter={false} />
      </div>
    </>
  );
}
